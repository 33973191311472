import React from 'react';
import PropTypes from 'prop-types';

import { Stage } from '@ccg/fork';

import PageTemplate from '../templates/page';

import Embeds from '../../shared/Embeds/Embeds';

const Standard = ({ data }) => {
  const { entry, footer, navigation, seoDefaults, cookieLayer } = data;

  return (
    <PageTemplate
      navigation={navigation}
      seo={entry}
      footer={footer}
      seoDefaults={seoDefaults}
      cookieLayer={cookieLayer}
    >
      <Stage
        image={
          entry.image &&
          entry.image.length > 0 && {
            ...entry.image[0],
            srcset: {
              '1440w': entry.image[0].width1440,
              '960w': entry.image[0].width960,
              '720w': entry.image[0].width720,
              '375w': entry.image[0].width375
            }
          }
        }
        video={entry.video && entry.video.length > 0 && entry.video[0]}
        loop={entry.loop}
        headline={entry.headline || entry.title}
        subline={entry.subline}
      />

      <Embeds embedsCopy={entry.embedsCopy} embeds={entry.embeds} />
    </PageTemplate>
  );
};

Standard.propTypes = {
  data: PropTypes.shape().isRequired
};

Standard.defaultProps = {};

export default Standard;
